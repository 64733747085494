import { AlertColor } from "@mui/material";
import { SHOW_ALERT, HIDE_ALERT } from "../../../constants/redux";

export interface AlertProps {
    type?: AlertColor;
    message: string;
    showAlert: boolean;
}
export const ShowAlert = (data: AlertProps) => ({
  type: SHOW_ALERT,
  payload: data,
});

export const HideAlert = (data: AlertProps) => ({
  type: HIDE_ALERT,
  payload: data,
});
