import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const PrivateRoute = ({ children }: any) => {
	const { isAuthenticated } = useSelector((state: RootState) => state.auth);
	if (isAuthenticated && window.location.pathname === "/login") {
		return <Navigate to={window.location.pathname} />;
	}

	return <>{children}</>;
};

export default PrivateRoute;