import {
	ADD_EQUIPMENT_SUCCESS,
	ADD_LOCATION_FAILURE,
	ADD_LOCATION_REQUEST,
	ADD_LOCATION_SUCCESS,
	ADD_PASSWORD_FAILURE,
	ADD_PASSWORD_SUCCESS,
	ADD_PERSONAL_DETAILS_FAILURE,
	ADD_PERSONAL_DETAILS_SUCCESS,
	ADD_SCHEDULE_REQUEST,
	ADD_SHOOT_TYPE_FAILURE,
	ADD_SHOOT_TYPE_SUCCESS,
	ADD_WORK_PREFERENCE_FAILURE,
	ADD_WORK_PREFERENCE_SUCCESS,
	FETCH_DEFAULT_LOCATIONS_SUCCESS,
	FETCH_EQUIPMENT_SUCCESS,
	GET_ACCOUNT_ID_FAILURE,
	GET_ACCOUNT_ID_REQUEST,
	GET_ACCOUNT_ID_SUCCESS,
	GET_ACCOUNT_LINK_FAILURE,
	GET_ACCOUNT_LINK_REQUEST,
	GET_ACCOUNT_LINK_SUCCESS,
	GET_PAYMENT_DETAILS_FAILURE,
	GET_PAYMENT_DETAILS_REQUEST,
	GET_PAYMENT_DETAILS_SUCCESS,
	GET_USER_ID_FAILURE,
	SET_PROFILE,
	SET_PROFILE_FAILURE,
	SET_PROFILE_SUCCESS,
	UPLOAD_PROFILE_PICTURE_REQUEST,
	VERIFY_STRIPE_USER_FAILURE,
	VERIFY_STRIPE_USER_REQUEST,
	VERIFY_STRIPE_USER_SUCCESS,
} from "../../../constants/redux";

const initialState: any = {};

// Helper function to update items based on action payload
const updateItems = (state: any, key: string, payload: any, statusKey: string) => {
	const updatedItems = state.data[key].map((item: any) => {
		let value = payload.filter((location: any) => item.id === location.id);
		item[statusKey] = value.length > 0 ? "selected" : "not";
		return item;
	});

	return {
		...state,
		loading: false,
		data: { ...state.data, [key]: updatedItems },
	};
};

const toggleStatus = (state: any, key: string, payload: any, statusKey: string) => {
	const updatedItems = state.data[key].map((item: any) => {
		if (item.itemid === payload) {
			// Toggle the status property for the matching item
			return {
				...item,
				[statusKey]: item[statusKey] === "Available" ? "not" : "Available",
			};
		}
		return item;
	});

	return {
		...state,
		loading: false,
		data: { ...state.data, [key]: updatedItems },
	};
};

const toggleStatusEquipment = (state: any, key: string, payload: any, statusKey: string, update: boolean) => {
	const itemAvailable = state.data[key].some((obj: any) => obj.id === payload[0].id);
	const updatedItems: any[] = state.data[key].map((item: any) => {
		if (item.id === payload[0].id && !update) {
			// Toggle the status property for the matching item
			return {
				...item,
				[statusKey]: item[statusKey] === "active" ? "not" : "active",
			};
		}
		if (item.id === payload[0].id && update) {
			return payload[0];
		}
		return item;
	});
	if (!itemAvailable) {
		updatedItems.push({
			...payload[0],
			[statusKey]: "active",
		});
	}

	return {
		...state,
		loading: false,
		data: { ...state.data, [key]: updatedItems },
	};
};

const profileReducer = (state = initialState, action: any): any => {
	switch (action.type) {
		case SET_PROFILE:
			return { ...state, loading: true, error: null };
		case ADD_LOCATION_REQUEST:
			return updateItems(state, "locations", action.payload, "locationStatus");
		case ADD_SCHEDULE_REQUEST:
			return toggleStatus(state.schedule, "data", action.payload, "status");

		case ADD_EQUIPMENT_SUCCESS:
			return toggleStatusEquipment(state, "equipment", action.payload.equipment, "status", action.payload.isUpdated);
		case ADD_SHOOT_TYPE_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...state.data, categories: action.payload },
			};
		case FETCH_EQUIPMENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...state.data, equipment: action.payload["Equipment"] },
			};

		case ADD_LOCATION_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...state.data, locations: action.payload },
			};
		case FETCH_DEFAULT_LOCATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...state.data, locations: action.payload["TaggedLocations"] },
			};
		case UPLOAD_PROFILE_PICTURE_REQUEST:
			return { ...state, uploading: false, data: { ...state.data, image_url: action.payload } };

		case SET_PROFILE_SUCCESS:
			return { ...state, loading: false, data: action.payload };
		case ADD_PERSONAL_DETAILS_SUCCESS: {
			const data = { ...state.data, ...action.payload };
			return {
				...state,
				loading: false,
				data,
			};
		}
		case ADD_PASSWORD_SUCCESS:
		case ADD_WORK_PREFERENCE_SUCCESS: {
			return { ...state, loading: false };
		}
		case SET_PROFILE_FAILURE:
		case ADD_PERSONAL_DETAILS_FAILURE:
		case ADD_PASSWORD_FAILURE:
		case ADD_WORK_PREFERENCE_FAILURE:
		case ADD_SHOOT_TYPE_FAILURE:
		case ADD_LOCATION_FAILURE:
			return { ...state, loading: false, error: action.payload };
		case GET_PAYMENT_DETAILS_REQUEST:
		case VERIFY_STRIPE_USER_REQUEST:
			return {
				...state,
				error: null,
			};
		case VERIFY_STRIPE_USER_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					wallet: {
						...state.data.wallet,
						isVerifying: false,
						stripeVerificationData: action.payload,
					},
				},
			};
		case VERIFY_STRIPE_USER_FAILURE:
			return {
				...state,
				data: {
					...state.data,
					wallet: {
						...state.data.wallet,
						error: action.payload,
					},
				},
			};

		case GET_ACCOUNT_ID_REQUEST:
		case GET_ACCOUNT_LINK_REQUEST:
			return {
				...state,
				error: null,
			};
		case GET_ACCOUNT_ID_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					wallet: {
						...state.data.wallet,
						accountId: action.payload.accountId,
					},
				},
			};

		case GET_ACCOUNT_LINK_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					wallet: {
						...state.data.wallet,
						accountLinkURL: action.payload.accountLinkURL,
					},
				},
			};

		case GET_USER_ID_FAILURE:
			return {
				...state,
				error: action.payload.error,
			};
		case GET_ACCOUNT_ID_FAILURE:
		case GET_ACCOUNT_LINK_FAILURE:
			return {
				...state,
				data: {
					...state.data,
					wallet: {
						...state.data.wallet,
						error: action.payload.error,
					},
				},
			};

		case GET_PAYMENT_DETAILS_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					wallet: {
						...state.data.wallet,
						isVerifying: false,
						paymentDetails: action.payload.paymentDetails,
					},
				},
				loading: false,
			};

		case GET_PAYMENT_DETAILS_FAILURE:
			return {
				...state,
				data: {
					...state.data,
					wallet: {
						...state.data.wallet,
						error: action.payload.error,
					},
				},
			};
		default:
			return state;
	}
};

export default profileReducer;
