import { useState } from "react";

import {
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Box,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";

import { leftSideBarRoutes } from "../constants/constants";

import PhotoppzLogo from "../assets/images/photoppzLogo.png";

import LogoIcon from "../assets/images/LogoIcon.png";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { logout } from "../store/actions/auth";
import { RootState } from "../store";

const drawerWidth = 272;

function LeftBar(props: any) {
	const theme = useTheme();
	const [clickedIndex, setClickedIndex] = useState<number | null>(null);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const logOut = () => {
		hideSideBar();
		props.logout();
	};

	const dashboard = () => {
		navigate("/dashboard");
	};

	const hideSideBar = () => {
		if (isExtraSmallScreen) props.closeSideBar();
	};

	return (
		<Drawer
			sx={{
				width: drawerWidth,
				textAlign: "center",
				flexShrink: 0,
				"& .MuiDrawer-paper": {
					width: props?.width,
					boxSizing: "border-box",
					borderRight: "none",
				},
				boxShadow: "4px 0px 40px 0px rgba(0, 0, 0, 0.08)",
			}}
			variant="permanent"
			anchor="left"
		>
			<List>
				{props.isLaptop ? (
					<Box
						sx={{
							marginTop: "38px",
							marginBottom: "40px",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Grid container>
							<Grid xs={12}>
								<img src={PhotoppzLogo} loading="lazy" alt="Photoppz Logo" onClick={dashboard} style={{ cursor: "pointer" }} />
							</Grid>
							<Grid xs={12} marginTop={"2.3%"}>
								<Typography
									variant="body_caps_16"
									fontFamily="fontFamily"
									sx={{
										fontStyle: "normal",
										letterSpacing: "0.32px",
									}}
									color={"#6B6267"}
								>
									Photographer portal
								</Typography>
							</Grid>
						</Grid>
					</Box>
				) : (
					<Box
						sx={{
							marginTop: "74px",
							marginBottom: "40px",
							display: "flex",
							alignItems: "center",
							pl: "10px",
							pr: "10px",
						}}
					>
						<img
							src={LogoIcon}
							loading="lazy"
							alt="Photoppz Logo"
							onClick={dashboard}
							style={{ cursor: "pointer" }}
							width="100%"
							height="100%"
						/>
					</Box>
				)}

				{leftSideBarRoutes.map((item, index) => {
					const pathNameValue =
						pathname === "/dashboard/events/event-view" ? pathname.substring(0, pathname.lastIndexOf("/")) : pathname;

					return (
						<>
							<ListItem
								key={item.title}
								disablePadding
								sx={{
									height: 80,
									bgcolor: pathNameValue === item.href ? "#4C8577" : "transparent",
									color: pathNameValue === item.href ? "white" : "#7D7D7D",
								}}
								onClick={() => setClickedIndex(index)}
							>
								<ListItemButton
									component={Link}
									to={item.href}
									onClick={() => (item.title === "Sign Out" ? logOut() : hideSideBar())}
									sx={{
										height: 80,
										padding: "0 18%",
										":hover": {
											color: pathNameValue !== item.href ? "#35514A" : "white",
											background: pathNameValue !== item.href ? "white" : null,
										},
										justifyContent: !props.isLaptop ? "center" : "flex-start",
									}}
								>
									{item.icon}

									{props.isLaptop && (
										<ListItemText sx={{ padding: "0px 20px" }}>
											<Typography variant="subtitle1">{item.title}</Typography>
										</ListItemText>
									)}
								</ListItemButton>
							</ListItem>
						</>
					);
				})}
			</List>
		</Drawer>
	);
}

const mapStateToProps = (state: RootState) => ({
	profile: state.profile.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			logout,
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar);
