import {
	ENABLE_RIGHT_BAR,
	FETCH_EVENT_REQUEST,
	FETCH_EVENT_SUCCESS,
	FETCH_EVENT_FAILURE,
	FETCH_EVENT_BY_ID_REQUEST,
	FETCH_EVENT_BY_ID_SUCCESS,
	FETCH_EVENT_BY_ID_FAILURE,
	FETCH_COMPLETED_EVENT_SUCCESS,
	FETCH_SESSION_DETAILS_SUCCESS,
	FETCH_SESSION_DETAILS_REQUEST,
	ADD_IMAGE_DETAILS_REQUEST,
	ADD_IMAGE_DETAILS_SUCCESS,
	ADD_IMAGE_DETAILS_FAILURE,
	SAVE_SURVEY_REQUEST,
	SAVE_SURVEY_FAILURE,
	SAVE_SURVEY_SUCCESS,
	FETCH_IMAGES_SUCCESS,
	FETCH_IMAGES_FAILURE,
	FETCH_IMAGES_REQUEST,
	ACCEPT_EVENT_FAILURE,
	ACCEPT_EVENT_REQUEST,
	ACCEPT_EVENT_SUCCESS,
	DECLINE_EVENT_FAILURE,
	DECLINE_EVENT_REQUEST,
	DECLINE_EVENT_SUCCESS,
	FETCH_INVITED_EVENTS_FAILURE,
	FETCH_INVITED_EVENTS_REQUEST,
	FETCH_INVITED_EVENTS_SUCCESS,
	FETCH_EVENT_BY_ID_UNDO,
} from "../../../constants/redux";

const initialState: any = {};

const reducers = (state = initialState, action: any) => {
	switch (action.type) {
		case ENABLE_RIGHT_BAR:
			return {
				...state,
			};
		case FETCH_SESSION_DETAILS_REQUEST:
			return { ...state, session: null, loading: true };
		case FETCH_EVENT_REQUEST:
		case FETCH_EVENT_BY_ID_REQUEST:
		case ADD_IMAGE_DETAILS_REQUEST:
		case SAVE_SURVEY_REQUEST:
		case FETCH_IMAGES_REQUEST:
		case FETCH_INVITED_EVENTS_REQUEST:
		case ACCEPT_EVENT_REQUEST:
		case DECLINE_EVENT_REQUEST:
			return { ...state, loading: true, error: null };
		case FETCH_EVENT_SUCCESS: {
			const modifiedArray = action.payload.map(
				(obj: { start: any; startDateTime: any; end: any; endDateTime: any; startDate: any }) => {
					obj.start = obj.startDateTime;
					obj.end = obj.endDateTime;
					return obj;
				}
			);
			return { ...state, loading: false, data: modifiedArray };
		}
		case FETCH_EVENT_FAILURE:
		case FETCH_EVENT_BY_ID_FAILURE:
		case ADD_IMAGE_DETAILS_FAILURE:
		case SAVE_SURVEY_FAILURE:
		case FETCH_IMAGES_FAILURE:
			return { ...state, loading: false, error: action.payload };
		case FETCH_EVENT_BY_ID_SUCCESS:
			return { ...state, loading: false, selectedData: action.payload };
		case FETCH_EVENT_BY_ID_UNDO:
			return { ...state, loading: false, selectedData: undefined };
		case FETCH_COMPLETED_EVENT_SUCCESS:
			return { ...state, loading: false, completedEvent: action.payload };
		case FETCH_SESSION_DETAILS_SUCCESS:
			return { ...state, loading: false, session: action.payload };
		case FETCH_IMAGES_SUCCESS:
			return {
				...state,
				loading: false,
				session: state.event.session ? { ...state.event.session, ...action.payload.images } : { ...action.payload.images },
			};
		case ADD_IMAGE_DETAILS_SUCCESS:
		case SAVE_SURVEY_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
			};

		case FETCH_INVITED_EVENTS_SUCCESS:
			return {
				...state,
				invitedEvents: action.payload,
				loading: false,
				error: null,
			};
		case FETCH_INVITED_EVENTS_FAILURE:
			return {
				...state,
				invitedEvents: [],
				loading: false,
				error: action.payload,
			};

		case ACCEPT_EVENT_SUCCESS: {
			const eventToUpdate = state.invitedEvents.map((event: { isAccepted: boolean; eventId: any }) => {
				if (event.eventId === action.payload) event["isAccepted"] = true;
				return event;
			});

			return {
				...state,
				invitedEvents: eventToUpdate,
				loading: false,
				error: null,
			};
		}
		case ACCEPT_EVENT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case DECLINE_EVENT_SUCCESS:
			const eventToUpdate = state.invitedEvents.map(
				(event: { isAccepted: boolean; isDeclined: boolean; eventId: any }) => {
					if (event.eventId === action.payload) event["isDeclined"] = true;
					return event;
				}
			);

			return {
				...state,
				invitedEvents: eventToUpdate,
				loading: false,
				error: null,
			};
		case DECLINE_EVENT_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return {
				...state,
			};
	}
};

export default reducers;
