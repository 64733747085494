// import "./App.css";
import { connect, useSelector } from "react-redux";
import { Box, ThemeProvider, Toolbar, IconButton, Badge, useMediaQuery, Zoom } from "@mui/material";
import AppRoutes from "./routes";
import customTheme from "../src/theme/index";

import CssBaseline from "@mui/material/CssBaseline";
import RightBar from "./components/rightBar";
import LeftBar from "./components/leftBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AlertComponent from "./components/alert";
import { ShowAlert, HideAlert } from "./store/actions/alert";
import { Dispatch, bindActionCreators } from "redux";
import MuiDrawer from "@mui/material/Drawer";

import { fetchProfileRequest } from "./store/actions/profile";
import { RootState } from "./store";
import { loadAuthFromStorage } from "./utils/authStorage";
import { loginSuccess } from "./store/actions/auth";
import { fetchNotificationRequest } from "./store/actions/notifications";
import HeaderProfileTag from "./components/headerProfileTag";

const drawerWidth = 272;
const lapScreen = 1537;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	background: theme.palette.secondary.main,
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: "flex-end",
}));

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: "64px",
	[theme.breakpoints.up("md")]: {
		width: "64px",
	},
	[theme.breakpoints.down("md")]: {
		width: "0px",
	},
});

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	//whiteSpace: 'nowrap',
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

function App(props: any) {
	const location = useLocation();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [notificationCount, setNotificationCount] = useState<number>(0);
	const navigate = useNavigate();
	const currentRoute = location.pathname;
	const [hideSideBar, setHideSideBar] = useState(true);
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const sideBarScreen = useMediaQuery(theme.breakpoints.down(lapScreen));
	const showRightSideBar = useMediaQuery(theme.breakpoints.up("lg"));
	const hiddenPaths = [
		"/signup",
		"/login",
		"/event-cancelled",
		"/event-confirmed",
	];

	const handleClick = () => {
		navigate("/dashboard/notifications");
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const showAlert = useSelector((state: any) => {
		return state.alert?.data?.showAlert;
	});

	useEffect(() => {
		const loadedStore = loadAuthFromStorage();

		loadedStore && props.loginSuccess(loadedStore);
		loadedStore && props.fetchNotificationRequest();
	}, []);

	useEffect(() => {
		props.isAuthenticated && !props.profile?.data?.name && props.fetchProfile();
	}, [props, props.isAuthenticated]);

	useEffect(() => {
		const count = props.notifications?.filter((n: { isRead: boolean }) => !n.isRead);
		setNotificationCount(count?.length);
	}, [props.notifications]);

	useEffect(() => {
		setHideSideBar(hiddenPaths.includes(location.pathname));
	}, [location.pathname]);

	return (
		<ThemeProvider theme={customTheme}>
			<Box bgcolor={"background.default"} color={"text.primary"} sx={{ display: "flex" }}>
				<Zoom in={showAlert}>
					<Box
						sx={
							isExtraSmallScreen
								? {
									position: "fixed",
									bottom: "5vh",
									right: "5vh",
									zIndex: 9999,
									left: "5vh",
								}
								: {
									position: "fixed",
									bottom: "5vh",
									right: "5vh",
									zIndex: 9999,
								}
						}
					>
						<AlertComponent />
					</Box>
				</Zoom>
				{hideSideBar ? (
					<AppRoutes />
				) : (
					<>
						<CssBaseline />
						<Box sx={{ display: "flex", width: "100%" }}>
							<CssBaseline />
							<AppBar position="fixed" open={open}
								sx={{
									zIndex: (theme) => theme.zIndex.drawer + (sideBarScreen ? 1 : 0),
									background: "#FFFFFF",
									boxShadow: "4px 0px 40px 0px #00000014",}}
							>
								<Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
									{sideBarScreen &&
										<IconButton
											color="inherit"
											aria-label="open drawer"
											onClick={handleDrawerOpen}
											edge="start"
											sx={{ mr: 2, ...(open && { display: "none" }) }}
										>
											<MenuIcon color="secondary" />
										</IconButton>
									}
									<Box
										sx={{
											position: "fixed",
											right: isExtraSmallScreen ? "16px" : "24px",
											zIndex: 1300,
										}}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												gap: isExtraSmallScreen ? 1 : 2
											}}
										>
											<IconButton
												size="large"
												aria-label={`show ${notificationCount} new notifications`}
												color="inherit"
												onClick={handleClick}
											>
												<Badge badgeContent={notificationCount} color="error">
													<NotificationsIcon color="secondary" />
												</Badge>
											</IconButton>
											<HeaderProfileTag />
										</Box>
									</Box>
								</Toolbar>
							</AppBar>
							{sideBarScreen ? (
								<>
									<Drawer variant="permanent" open={open}>
										<DrawerHeader sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
											<IconButton onClick={handleDrawerClose}>
												{theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
											</IconButton>
										</DrawerHeader>
										<LeftBar isLaptop={open} closeSideBar={handleDrawerClose} />
									</Drawer>
									<Box component="main" sx={{ p: "0px", width: "100%" }}>
										<DrawerHeader />
										<Box
											sx={{
												backgroundColor: "#EFEDEB",
												minHeight: "100vh",
												width: "100%",
												paddingLeft: isExtraSmallScreen ? "15px" : 5,
												paddingRight: isExtraSmallScreen ? "15px" : 5,
												height: "100%",
												paddingTop: 5,
											}}
										>
											<AppRoutes />
										</Box>
									</Box>
								</>
							) : (
								<>
									<LeftBar isLaptop={true} width={drawerWidth} />
									<Box
										sx={{
											backgroundColor: "#EFEDEB",
											minHeight: "100vh",
											width: "100%",
											paddingLeft: 5,
											paddingRight: 5,
											height: "100%",
											paddingTop: 10,
											paddingBottom: 5,
										}}
									>
										<AppRoutes />
									</Box>
								</>
							)}
						</Box>

						{showRightSideBar && !!(props.rightDashboardSidebar | props.rightEventSidebar) && <RightBar {...props} />}
					</>
				)}
			</Box>
		</ThemeProvider>
	);
}

const mapStateToProps = (state: RootState) => ({
	rightDashboardSidebar: state.sidebar.rightSidebarDashboardVisible,
	rightEventSidebar: state.sidebar.rightSidebarEventVisible,
	profile: state.profile.data && state.profile.data.name,
	isAuthenticated: state.auth?.isAuthenticated,
	notifications: state.notification.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators(
		{
			showAlert: ShowAlert,
			hideAlert: HideAlert,
			fetchProfile: fetchProfileRequest,
			loginSuccess,
			fetchNotificationRequest,

		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
