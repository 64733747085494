import { takeLatest, call, all } from "redux-saga/effects";
import axios from "axios";

import { REQUEST_SIGNED_URL_PROFILE, REQUEST_SIGNED_URL_GALLERY } from "../../../constants/redux";
import { commonApiCall } from "../../apiCaller";

type GeneratorReturnType<T> = Generator<any, T, any>;
// Function to request a signed URL for a single profile image
function* fetchProfileSignedUrl(action: any): GeneratorReturnType<void> {
	const { imageName, file, onUploadSuccess } = action.payload;
	try {
		const response = yield call(commonApiCall, `/uploads/profile-images`, "PUT", { image: imageName }, {});

		const signedUrl = response.signed_urls[0]?.url;

		if (signedUrl) {
			yield call(uploadToS3, signedUrl, file);
			onUploadSuccess(signedUrl); // Callback for success
		} else {
			throw new Error("No signed URL returned for profile image.");
		}
	} catch (error: any) {
		console.error(error);
		onUploadSuccess(null); // Handle failure callback
	}
}

// Function to request signed URLs for multiple gallery images
function* fetchGallerySignedUrls(action: any): GeneratorReturnType<void> {
	const { galleryData, files, onUploadSuccess, setUploadCount } = action.payload;
	try {
		const response = yield call(commonApiCall, `/uploads/gallery-images`, "PUT", galleryData, {});
		const signedUrls = response.signed_urls;
		if (!signedUrls || signedUrls.length === 0) {
			throw new Error("No signed URLs returned from the server.");
		  }
	  
		const signedUrlsList = signedUrls.map((item: { url: string }) => item.url);

		let uploadedCount = 0;

		// Upload images one by one and track count
		for (let index = 0; index < signedUrls.length; index++) {
		  try {
			yield call(uploadToS3, signedUrls[index].url, files[index]);
			uploadedCount++;
			yield call(setUploadCount, uploadedCount); // Update the count in the UI
		  } catch (error) {
			console.error(`Error uploading ${files[index].name}:`, error);
		  }
		}
	

		onUploadSuccess(signedUrlsList); // Callback for success
	} catch (error: any) {
		console.error(error);
		onUploadSuccess(null); // Handle failure callback
	}
}

// Function to upload image to S3
function* uploadToS3(signedUrl: string, file: File) {
	try {
		yield call(axios.put, signedUrl, file, {
			headers: {
				"Content-Type": file.type,
			},
		});
	} catch (error: any) {
		console.error(error);
	}
}

// Watcher Saga
function* uploadSaga() {
	yield takeLatest(REQUEST_SIGNED_URL_PROFILE, fetchProfileSignedUrl);
	yield takeLatest(REQUEST_SIGNED_URL_GALLERY, fetchGallerySignedUrls);
}

export function* s3Saga() {
	yield all([uploadSaga()]);
}
