import { Avatar, Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { RootState } from "../store";
import { connect } from "react-redux";
import { profileKeys } from "../constants/profile";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { bindActionCreators, Dispatch } from "redux";
import { logout } from "../store/actions/auth";

const { NAME, IMAGE_URL } = profileKeys.profile;

function HeaderProfileTag(props: any) {
    const theme = useTheme();
    const navigate = useNavigate();
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const logOut = () => {
        props.logout();
        navigate("/login");
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                }}
                onClick={() => { navigate("/dashboard/profile") }}
            >
                <Avatar
                    alt="Remy Sharp"
                    src={(props.profile && props.profile[IMAGE_URL]) ?? null}
                    sx={
                        isExtraSmallScreen ?
                            { width: 32, height: 32 } :
                            { width: 40, height: 40 }
                    }
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Typography color="secondary.main" variant="subtitle2">
                        {(props.profile && props.profile[NAME].length > 9
                            ? props.profile[NAME].substring(0, 9).concat("...")
                            : props.profile && props.profile[NAME]) ?? ""}
                    </Typography>
                </Box>
            </Box>
            <ExitToAppIcon
                color="secondary"
                sx={{
                    cursor: "pointer",
                    "&:hover": {
                        background: "rgba(0, 0, 0, 0.04)",
                    },
                }}
                onClick={logOut}
            />
        </Box>
    )
}

const mapStateToProps = (state: RootState) => ({
    profile: state.profile.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            logout,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderProfileTag);