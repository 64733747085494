// saga.ts
import { all, takeLatest, put, call, takeEvery } from "redux-saga/effects";
import { commonApiCall } from "../../apiCaller";
import {
	ADD_EQUIPMENT_REQUEST,
	ADD_PASSWORD_REQUEST,
	ADD_PERSONAL_DETAILS_REQUEST,
	ADD_WORK_PREFERENCE_REQUEST,
	SET_PROFILE,
	SET_PROFILE_SUCCESS,
	FETCH_EQUIPMENT_REQUEST,
	FETCH_DEFAULT_LOCATIONS_REQUEST,
	ADD_ONBOARDING_LOCATION_REQUEST,
	ADD_SHOOT_TYPE_API_REQUEST,
	SAVE_STRIPE_ID_REQUEST,
	SAVE_STRIPE_ACCOUNT_STATUS_REQUEST,
	VERIFY_STRIPE_USER_REQUEST,
	GET_ACCOUNT_ID_REQUEST,
	GET_ACCOUNT_LINK_REQUEST,
	GET_PAYMENT_DETAILS_REQUEST,
} from "../../../constants/redux";
import {
	addEquipmentFailure,
	addEquipmentSuccess,
	addLocationFailure,
	addLocationSuccess,
	addPasswordFailure,
	addPasswordSuccess,
	addPersonalDetailsSuccess,
	addShootTypesFailure,
	addShootTypesSuccess,
	addWorkPreferenceFailure,
	addWorkPreferenceSuccess,
	fetchDefaultLocationsSuccess,
	fetchEquipmentSuccess,
} from "../../actions/profile";
import { ShowAlert } from "../../actions/alert";
import {
	getAccountIDFailure,
	getAccountIDSuccess,
	getAccountLinkFailure,
	getAccountLinkRequest,
	getAccountLinkSuccess,
	getPaymentDetailsFailure,
	getPaymentDetailsRequest,
	getPaymentDetailsSuccess,
	saveStripeAccountStatusFailure,
	saveStripeAccountStatusSuccess,
	saveStripeIdFailure,
	saveStripeIdRequest,
	saveStripeIdSuccess,
	verifyStripeUserFailure,
	verifyStripeUserSuccess,
} from "../../actions/stripe";
import qs from "qs";
import {
	COMMON_ERROR_MESSAGE,
	COMMON_SUCCESSFULL_MESSAGE,
	COMMON_SAVE_ERROR_MESSAGE,
} from "../../../constants/redux/messages";

type GeneratorReturnType<T> = Generator<any, T, any>;
const profilePath = "";

function* handleSetProfile(action: any): GeneratorReturnType<void> {
	try {
		const response: any = yield call(commonApiCall, `${profilePath}/profile`, "GET", {}, {}, {});

		yield put({
			type: SET_PROFILE_SUCCESS,
			payload: response,
		});
	} catch (error) {
		console.error("Error fetching profile:", error);
	}
}

/*********************************Manage Personal Details***********************************/
function* addPersonalDetailsSaga(action: any): GeneratorReturnType<void> {
	const initialStatus = action?.payload?.isInitial;
	const modifiedData = () => {
		if (initialStatus) {
			const { firstName, lastName, phoneNumber } = action && action.payload;
			return {
				firstName,
				lastName,
				phoneNumber,
			};
		}
		const { fullName, email, phoneNumber } = action?.payload?.personalDetails;
		return { fullName, email, phoneNumber };
	};

	const changedData = yield modifiedData();

	try {
		const response: any = yield call(
			commonApiCall,
			`${profilePath}/profile-change`,
			initialStatus ? "POST" : "PUT",
			changedData,
			{}
		);

		yield put(addPersonalDetailsSuccess(changedData));
		yield put(
			ShowAlert({
				showAlert: true,
				message: response.message || COMMON_SUCCESSFULL_MESSAGE,
				type: "success",
			})
		);
	} catch (error: any) {
		yield put(
			ShowAlert({
				showAlert: true,
				message: error.message || COMMON_SAVE_ERROR_MESSAGE,
				type: "error",
			})
		);
		console.error("Error fetching profile:", error);
	}
}

/*********************************Manage Password Details***********************************/
function* addPasswordSaga(action: any): GeneratorReturnType<void> {
	try {
		const response: any = yield call(
			commonApiCall,
			`${profilePath}/password-change`,
			action.payload && action.payload.isInitial ? "POST" : "PUT",
			{ password: action.payload.password },
			{}
		);
		yield put(addPasswordSuccess(response));
		yield put(
			ShowAlert({
				showAlert: true,
				message: response.message || COMMON_SUCCESSFULL_MESSAGE,
				type: "success",
			})
		);
	} catch (error: any) {
		yield put(addPasswordFailure(error.message));
		yield put(
			ShowAlert({
				showAlert: true,
				message: error.message || COMMON_SAVE_ERROR_MESSAGE,
				type: "error",
			})
		);
	}
}

/*********************************Manage Work Preference Details***********************************/
function* addWorkPreference(action: any): GeneratorReturnType<void> {
	try {
		const response: any = yield call(commonApiCall, `${profilePath}/work-preference`, "PUT", action.payload, {});

		yield put(addWorkPreferenceSuccess(response));
	} catch (error: any) {
		yield put(addWorkPreferenceFailure(error.message));
	}
}

/*********************************Manage Location Details***********************************/
function* addLocation(action: any): GeneratorReturnType<void> {
	try {
		yield call(
			commonApiCall,
			`${profilePath}/locations`,
			action.payload.isInitial ? "POST" : "PUT",
			action.payload.location || action.payload,
			{}
		);

		yield put(addLocationSuccess(action.payload.location || action.payload));
	} catch (error: any) {
		yield put(addLocationFailure(error.message));
	}
}

/*********************************Manage Shoot Type/ Gear List Details***********************************/
function* addShootTypes(action: any): GeneratorReturnType<void> {
	try {
		const response: any = yield call(commonApiCall, `${profilePath}/categories`, "PUT", action.payload, {});

		yield put(addShootTypesSuccess(action.payload));
		yield put(
			ShowAlert({
				showAlert: true,
				message: response.message || COMMON_SUCCESSFULL_MESSAGE,
				type: "success",
			})
		);
	} catch (error: any) {
		yield put(addShootTypesFailure(error.message));
		yield put(
			ShowAlert({
				showAlert: true,
				message: error.message || COMMON_SAVE_ERROR_MESSAGE,
				type: "error",
			})
		);
	}
}

/*********************************Manage Equipment Details***********************************/
function* addEquipment(action: any): GeneratorReturnType<void> {
	try {
		yield call(commonApiCall, `${profilePath}/equipment`, "PUT", action.payload, {});

		yield put(addEquipmentSuccess(action.payload));
	} catch (error: any) {
		yield put(addEquipmentFailure(error.message));
	}
}

function* fetchEquipment(action: any): GeneratorReturnType<void> {
	try {
		const response: any = yield call(commonApiCall, `${profilePath}/equipment`, "GET", {}, {});

		yield put(fetchEquipmentSuccess(response));
	} catch (error: any) {
		yield put(addEquipmentFailure(error.message));
		yield put(
			ShowAlert({
				showAlert: true,
				message: error.message || COMMON_ERROR_MESSAGE,
				type: "error",
			})
		);
	}
}

function* fetchDefaultLocations(action: any): GeneratorReturnType<void> {
	try {
		const response: any = yield call(commonApiCall, `${profilePath}/locations`, "GET", {}, {});

		yield put(fetchDefaultLocationsSuccess(response));
	} catch (error: any) {
		yield put(addLocationFailure(error.message));
		yield put(
			ShowAlert({
				showAlert: true,
				message: error.message || COMMON_ERROR_MESSAGE,
				type: "error",
			})
		);
	}
}

//Stripe Saga Calls
const dashboardUrl = "profile/1000";
const eventPath = "wallet";

function* saveStripeIdSaga(action: any): GeneratorReturnType<void> {
	try {
		// Replace the API endpoint with your actual endpoint for saving the Stripe ID
		yield call(
			commonApiCall,
			`${dashboardUrl}/${eventPath}`,
			"POST",
			{ stripeId: action.payload.stripeId }, // Assuming your payload contains the Stripe ID
			{}
		);

		yield put(saveStripeIdSuccess());
	} catch (error: any) {
		yield put(saveStripeIdFailure(error.message));
	}
}

function* saveStripeAccountStatusSaga(action: any): GeneratorReturnType<void> {
	try {
		// Replace the API endpoint with your actual endpoint for saving the account status
		yield call(commonApiCall, "/save-stripe-account-status", "POST", { status: action.payload.status }, {});

		yield put(saveStripeAccountStatusSuccess());
	} catch (error: any) {
		yield put(saveStripeAccountStatusFailure(error.message));
	}
}

function* verifyUserSaga(action: any): GeneratorReturnType<void> {
	try {
		// Replace the API endpoint with your actual endpoint for verifying the user
		const response = yield call(
			commonApiCall,
			`https://api.stripe.com/v1/customers/${action.payload.customerId}`,
			"GET",
			{},
			{},
			{
				Authorization: `Bearer ${process.env.REACT_APP_RESTRICTED_TOKEN}`,
			}
		);

		// Check the response and handle success accordingly
		if (response) {
			yield put(verifyStripeUserSuccess(response));
			yield put(getPaymentDetailsRequest(action.payload.customerId));
		} else {
			// Handle failure cases
			yield put(verifyStripeUserFailure("Failed to verify user."));
		}
	} catch (error: any) {
		yield put(verifyStripeUserFailure(error.message));
		yield put(
			ShowAlert({
				showAlert: true,
				message: error.message || COMMON_ERROR_MESSAGE,
				type: "error",
			})
		);
	}
}

function* getPaymentDetailsSaga(action: any): GeneratorReturnType<void> {
	try {
		const response = yield call(
			commonApiCall,
			`https://api.stripe.com/v1/customers/${action.payload.customerId}/payment_methods`,
			"GET",
			{},
			{},
			{
				Authorization: `Bearer ${process.env.REACT_APP_RESTRICTED_TOKEN}`,
			}
		);

		if (response && response.data) {
			yield put(getPaymentDetailsSuccess(response.data[0]));
		} else {
			yield put(getPaymentDetailsFailure("Failed to get payment details."));
		}
	} catch (error: any) {
		yield put(getPaymentDetailsFailure(error.message));
		yield put(
			ShowAlert({
				showAlert: true,
				message: error.message || COMMON_ERROR_MESSAGE,
				type: "error",
			})
		);
	}
}

function* getAccountIDSaga(action: any): GeneratorReturnType<void> {
	try {
		const data = qs.stringify({ type: "standard" });
		const response = yield call(
			commonApiCall,
			"https://api.stripe.com/v1/accounts", // Replace with your actual API endpoint
			"POST",
			data,
			{},
			{
				Authorization: `Bearer ${process.env.REACT_APP_STRIPE_TOKEN}`,
			}
		);

		if (response.id) {
			// const data = yield response.json();
			const accountID = response.id;
			yield put(getAccountIDSuccess(accountID));
			yield put(saveStripeIdRequest(accountID));
			yield put(getAccountLinkRequest(accountID));
		} else {
			yield put(getAccountIDFailure("Failed to get account ID."));
		}
	} catch (error: any) {
		yield put(getAccountIDFailure(error.message));
	}
}

function openWindow(url: any) {
	window.open(url, "_blank", "noopener,noreferrer");
}

function* getAccountLinkSaga(action: any): GeneratorReturnType<void> {
	try {
		const data = qs.stringify({
			account: action.payload,
			type: "account_onboarding",
			refresh_url: "https://example.com/reauth",
			return_url: "https://example.com/return",
		});

		const response = yield call(
			commonApiCall,
			"https://api.stripe.com/v1/account_links", // Replace with your actual API endpoint
			"POST",
			data,
			{},
			{
				Authorization: `Bearer ${process.env.REACT_APP_RESTRICTED_TOKEN}`,
			}
		);

		if (response) {
			const accountLinkURL = response.url;
			yield put(getAccountLinkSuccess(accountLinkURL));
			yield call(openWindow, accountLinkURL);
		} else {
			yield put(getAccountLinkFailure("Failed to get account link URL."));
		}
	} catch (error: any) {
		yield put(getAccountLinkFailure(error.message));
	}
}

export function* profileSaga() {
	yield all([
		takeLatest(SET_PROFILE, handleSetProfile),
		takeLatest(ADD_PERSONAL_DETAILS_REQUEST, addPersonalDetailsSaga),
		takeLatest(ADD_PASSWORD_REQUEST, addPasswordSaga),
		takeLatest(ADD_WORK_PREFERENCE_REQUEST, addWorkPreference),
		takeEvery(ADD_EQUIPMENT_REQUEST, addEquipment),
		takeEvery(FETCH_EQUIPMENT_REQUEST, fetchEquipment),
		takeEvery(ADD_ONBOARDING_LOCATION_REQUEST, addLocation),
		takeEvery(ADD_SHOOT_TYPE_API_REQUEST, addShootTypes),
		takeEvery(FETCH_DEFAULT_LOCATIONS_REQUEST, fetchDefaultLocations),
		takeEvery(SAVE_STRIPE_ID_REQUEST, saveStripeIdSaga),
		takeEvery(SAVE_STRIPE_ACCOUNT_STATUS_REQUEST, saveStripeAccountStatusSaga),
		takeEvery(VERIFY_STRIPE_USER_REQUEST, verifyUserSaga),
		takeEvery(GET_ACCOUNT_ID_REQUEST, getAccountIDSaga),
		takeEvery(GET_ACCOUNT_LINK_REQUEST, getAccountLinkSaga),
		takeEvery(GET_PAYMENT_DETAILS_REQUEST, getPaymentDetailsSaga),
	]);
}
