import { Card, Typography, Box } from "@mui/material";
import { formatDateTime } from "../../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { readSingleNotificationRequest } from "../../store/actions/notifications";
import { bindActionCreators, Dispatch } from "redux";

interface Props {
  title: string;
  description: string;
  dateAndTime: string;
  typeInfo: {
    refId: string;
    type: string;
  };
  relationId?: string;
  id?: string
  readSingleNotification: (ids: any, status: boolean) => void;
}

function NotificationSideBarCard({
  title,
  description,
  dateAndTime,
  typeInfo,
  id,
  readSingleNotification
}: Props) {
  const navigate = useNavigate();
  const status = useSelector((state: any) => {
    if (typeInfo.type === "event") {
      const eventList = state?.event?.data?.find(
        (event: any) => event.eventId == typeInfo.refId
      );
      return eventList && eventList.status;
    }
  });
  const notificationNavigate = () => {
    id && readSingleNotification(id, true);
    switch (typeInfo.type) {
      case "earning":
        navigate("/dashboard/earnings", {
          state: {
            earningId: typeInfo.refId,
          },
        });
        break;
      case "event":
        if (status !== "completed") {
          navigate("/dashboard/events", {
            state: {
              isNavFromComponent: true,
              eventId: typeInfo.refId,
            },
          });
        } else {
          navigate("/dashboard/events", {
            state: {
              isNavFromComponent: true,
              eventId: typeInfo.refId,
              // completed: true,
            },
          });
        }
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Card
        sx={{
          maxWidth: 345,
          borderRadius: "12px",
          boxShadow: "2px 4px 9px 0px #00000012",
          cursor: "pointer",
        }}
        onClick={notificationNavigate}
      >
        <Box sx={{ padding: "16px 24px" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", mb: "8px" }}
            gap={1}
          >
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ flex: "1 1 auto", lineHeight: "1.25" }}
            >
              {title}
            </Typography>
            <Typography
              variant="small_body_regular_12"
              color={"#B7B7B7"}
              sx={{ marginLeft: "auto", flex: "0 0 auto" }}
            >
              {formatDateTime(dateAndTime)}
            </Typography>
          </Box>

          <Typography variant="body1" color="grey.200">
            {description}
          </Typography>
        </Box>
      </Card>
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      readSingleNotification: readSingleNotificationRequest,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(NotificationSideBarCard);