import axios, { AxiosInstance } from "axios";
import store from ".";
import { clearAuthFromStorage, saveAuthToStorage as saveAuthDataToStorage } from "../utils/authStorage";
import { loginSuccess, logout } from "./actions/auth";

const URL = "https://av6ccmjtye.execute-api.us-east-1.amazonaws.com/dev";

const api: AxiosInstance = axios.create({
	baseURL: URL,
});

let unauthorizedCount = 0; // Track consecutive 401 errors
let refreshTokenPromise: Promise<any> | null = null;
let corsErrorCount = 0; // Track consecutive CORS errors
// Request Interceptor
api.interceptors.request.use(
	(config) => {
		const { accessToken: token } = store.getState().auth;

		if (config.headers) {
			config.headers["x-api-key"] = process.env.REACT_APP_X_API_KEY || "";
			if (token) config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	(error) => Promise.reject(error)
);

// Response Interceptor
api.interceptors.response.use(
	(response) => {
		unauthorizedCount = 0; // Reset unauthorized error count
		corsErrorCount = 0; // Reset CORS error count on success
		return response;
	},
	async (error) => {
		// Handle CORS errors
		if (!error.response || error.message === "Network Error") {
			corsErrorCount++;
			console.error(`CORS error detected (${corsErrorCount}/3).`);

			if (corsErrorCount >= 3) {
				console.warn("Too many CORS errors, redirecting to login.");
				logoutAndRedirect();
			}
			return Promise.reject(error);
		}

		// Reset CORS error count if another type of error occurs
		corsErrorCount = 0;

		// Handle unauthorized errors
		return handleUnauthorizedError(error);
	}
);

async function handleUnauthorizedError(error: any) {
	const { response } = error;

	if (response?.status === 401) {
		console.warn("Unauthorized request detected:", response.data?.message);
		const { refreshToken } = store.getState().auth;

		// Check if refresh token exists & token expired
		if (refreshToken && response.data?.message === "The incoming token has expired") {
			if (!refreshTokenPromise) {
				refreshTokenPromise = refreshAccessToken(refreshToken);
			}

			try {
				const newAuthData = await refreshTokenPromise;
				refreshTokenPromise = null; // Reset after success

				// Retry original request with new token
				error.config.headers["Authorization"] = `Bearer ${newAuthData.accessToken}`;
				return axios(error.config);
			} catch (refreshError) {
				console.error("Error refreshing token:", refreshError);
				refreshTokenPromise = null;
				logoutAndRedirect();
			}
		} else {
			unauthorizedCount++;
		}
	}

	// Redirect to login if too many unauthorized attempts
	if (unauthorizedCount >= 5) {
		console.warn("Too many unauthorized requests, redirecting to login.");
		logoutAndRedirect();
	}

	return Promise.reject(error);
}

// Refresh Token Request
async function refreshAccessToken(refreshToken: string) {
	try {
		const refreshResponse = await axios.post("/idp/refresh", { refreshToken });

		const newAuthData = {
			accessToken: refreshResponse.data.AuthenticationResult.AccessToken,
			idToken: refreshResponse.data.AuthenticationResult.IdToken,
			refreshToken, // Keep same refresh token
			expiresAt: Date.now() + refreshResponse.data.AuthenticationResult.ExpiresIn * 1000,
		};

		saveAuthDataToStorage(newAuthData);
		store.dispatch(loginSuccess(newAuthData));

		return newAuthData;
	} catch (error) {
		throw error;
	}
}

function logoutAndRedirect() {
	store.dispatch(logout());
	clearAuthFromStorage();
	window.location.href = "/login";
}

// General API Call Function
export const commonApiCall = async (endpoint: string, method: string, data?: any, queryParams?: {}, header?: {}) => {
	try {
		const response = await api.request({
			url: queryParams ? buildUrlWithParams(endpoint, queryParams) : endpoint,
			method,
			data,
			headers: header,
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};

// Helper function for query parameters
const buildUrlWithParams = (url: string, params?: Record<string, string>) => {
	if (!params) return url;

	const queryString = Object.keys(params)
		.map((key) => `${key}=${encodeURIComponent(params[key])}`)
		.join("&");

	return `${url}?${queryString}`;
};
